import React from "react";
import ModalVideo from 'react-modal-video'
import { state } from "../../../states/tem9";
import Form2 from "../..//Form/Form2";
import "bootstrap/dist/css/bootstrap.min.css";
import phoneIcon from "../../../assets/img/new_template/tel.svg";
import emailIcon from "../../../assets/img/new_template/email.svg";
import pkt1 from "../../../assets/img/new_template/pkt1.svg";
import pkt2 from "../../../assets/img/new_template/pkt2.svg";
import pkt3 from "../../../assets/img/new_template/pkt3.svg";
import pkt4 from "../../../assets/img/new_template/pkt4.svg";
import iconPlay from "../../../assets/img/new_template/icon-play.svg";
import { Container as BootstrapContainer } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logoImg from "../../../assets/img/new_template/Avon_RGB_Logo.svg";
import qr from "../../../assets/img/new_template/qr-avon-on-app.png";
import CleoDesktop from "../../../assets/img/new_template/baner.jpg";
import CleoMobile from "../../../assets/img/new_template/baner-m.jpg";
import arrowUp from "../../../assets/img/new_template/arrow-up.svg";

import css from "./template.module.scss";
import 'react-modal-video/scss/modal-video.scss';

class Template11 extends React.Component {
  constructor() {
    super();
    this.state = {
      ...state, // Assuming your existing state is spread here
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this); // Bind the new handler
  }

  openModal() {
    this.setState({ isOpen: true });
  }

    // Metoda wykrywająca system operacyjny
    detectOS = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
      if (/android/i.test(userAgent)) {
        return "Android";
      }
  
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
  
      return "unknown";
    };

  scrollToTop(e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This makes the scrolling smooth
    });
  }

  formatPhone = (phone) => {
    return phone.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
  };

  
  componentDidMount() {
    if (this.props.templateId === 11 || this.props.tempId === 11) {
      // Dynamically import the CSS only if templateId is 11
      //  add custom class to body
      document.body.classList.add('template11');
      import('./custom.css').then(() => {
        console.log('CSS loaded for Template11');
      });
    }
  }

  // remove custom class from body
  componentWillUnmount() {
    document.body.classList.remove('template11');
  }

  render() {
    const os = this.detectOS();
    return (
      <div className={css.landing}>
        <BootstrapContainer className={`${css.text_left} mt-3`}>
          <Row>
            <Col lg={6} className="justify-content-start align-items-start pt-lg-4">
              <div className="d-lg-flex">
                <img className={css.logo} src={logoImg} alt="logo" />
                <div className="ms-lg-3 mt-4 mt-lg-0">
                  <h1>Dołącz do</h1>
                  <h1 className={css.secondary}>mojego zespołu</h1>
                </div>
              </div>
              <div className={`${css.pinkbox} mt-3 p-4`}>
                <div className={`${css.text_semibold} fs-4`}>
                  {this.props.name}
                </div>
                {/* telefon i email with icons */}
                <div className="d-xxl-flex mt-3">
                  <div className={css.mobileScale + " d-flex align-items-center fs-5"}>
                    <img src={phoneIcon} alt="phone" />
                    <div className={css.text_semibold + " ms-2"}>{this.formatPhone(this.props.phone)}</div>
                  </div>
                  <div className={css.mobileScale  + " d-flex align-items-center ms-xxl-4 fs-5"}>
                    <img src={emailIcon} alt="email" />
                    <a
                      href="mailto:
                    test@wp.pl"
                      className={`${css.text_semibold} ms-2`}
                    >
                      {this.props.uplnEmail}
                    </a>
                  </div>
                </div>
              </div>
              <div className={`${css.benefits} d-none d-lg-block`}>
                <div className={`d-flex align-items-center my-4`}>
                  <img src={pkt1} alt="pkt1" />
                  <div className="ms-3">
                    Zyskujesz <b>RABAT OD 15%</b> na start <b>DO 35%!</b>
                  </div>
                </div>
                <div className={`d-flex align-items-center my-4`}>
                  <img src={pkt2} alt="pkt2" />
                  <div className="ms-3">
                    Zamawiasz, kiedy chcesz - bez żadnych ograniczeń.
                  </div>
                </div>
                <div className={`d-flex align-items-center my-4`}>
                  <img src={pkt3} alt="pkt3" />
                  <div className="ms-3">
                    Masz dostęp do atrakcyjnych programów, promocji i
                    przedpremierowych nowości.
                  </div>
                </div>
                <div className={`d-flex align-items-center my-4`}>
                  <img src={pkt4} alt="pkt4" />
                  <div className="ms-3">
                    Zarabiasz na prowizji - online lub offline.
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 5, offset:1 }} xxl={{ span: 4, offset: 1 }}>
            <Form2
            landing={true}
            uplnEmail={this.props.uplnEmail}
            phone={this.props.phone}
            leaderId={this.props.leaderId}
            pageId={this.props.pageId}
            tags={this.props.tags}
            tempId={this.props.tempId}
            test={this.props.test}
          />
                        <div className={`${css.benefits} mt-3 d-block d-lg-none`}>
                <div className={`${css.bg_whiter} d-flex align-items-center my-4`}>
                  <img src={pkt1} alt="pkt1" />
                  <div className="ms-3">
                    Zyskujesz <b>RABAT OD 15%</b> na start <b>DO 35%!</b>
                  </div>
                </div>
                <div className={`${css.bg_whiter} d-flex align-items-center my-4`}>
                  <img src={pkt2} alt="pkt2" />
                  <div className="ms-3">
                    Zamawiasz, kiedy chcesz - bez żadnych ograniczeń.
                  </div>
                </div>
                <div className={`${css.bg_whiter} d-flex align-items-center my-4`}>
                  <img src={pkt3} alt="pkt3" />
                  <div className="ms-3">
                    Masz dostęp do atrakcyjnych programów, promocji i
                    przedpremierowych nowości.
                  </div>
                </div>
                <div className={`${css.bg_whiter} d-flex align-items-center my-4`}>
                  <img src={pkt4} alt="pkt4" />
                  <div className="ms-3">
                    Zarabiasz na prowizji - online lub offline.
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* middle section */}
          <div className="my-5 pb-5">
          <Row>
            <Col lg={4} className="p-4 py-lg-4 px-lg-0 bg-white">
              <div>
                <h1>Działasz</h1>
                <h1 className={css.secondary}>na własnych</h1>
                <h1>zasadach</h1>
              </div>
            </Col>
            <Col lg={4} className="p-4 bg-white">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h2>Aplikacja</h2>
                  <h2 className={css.secondary}>Avon On</h2>
                </div>
                {/* qr code */}
                <div>
                  <img
                    src={qr}
                    alt="
                qr"
                    className={css.qr + " d-none d-md-block"}
                  />
                </div>
              </div>
              <div className="mb-3 fs-5">
                <b>Konto Avon w Twoim telefonie.</b>
              </div>
              <div className="fs-5 mb-3 mb-md-0">
                Stały dostęp do cyfrowego katalogu oraz możliwość zarządzania
                zamówieniami <br className="d-block d-md-none d-lg-block d-xxl-none"></br> i płatnościami jednym kliknięciem.
              </div>
              <a className={css.button + " d-md-none"} href="http://onelink.to/nqndm9" target="_blank" rel="noopener noreferrer">PRZEJDŹ DO APLIKACJI</a>
            </Col>
            <Col lg={4} className={`${css.box1} p-4`}></Col>
          </Row>
          <Row>
            <Col lg={4} className="p-4 bg-white">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <h2>Katalog</h2>
                  <h2 className={css.secondary}>online</h2>
                </div>
              </div>
              <div className="mb-3 fs-5">
                <b>
                  Cyfrowa wersja Twojego <br></br> spersonalizowanego iKatalogu.
                </b>
              </div>
              <div className="fs-5">
                Możesz go dowolnie udostępniać, zbierając zamówienia online z
                opcją szybkiej wysyłki.
              </div>
            </Col>
            <Col lg={4} className={`${css.box2} p-4`}></Col>
            <Col lg={4} className="p-4 bg-white">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <div>
                  <h2>Platforma</h2>
                  <h2 className={css.secondary}>szkoleniowa</h2>
                </div>
              </div>
              <div className="mb-3 fs-5">
                <b>
                  Nieograniczony dostęp do <br></br> Avon Akademii.
                </b>
              </div>
              <div className="fs-5">
                Atrakcyjne materiały szkoleniowe, kursy beauty <br className="d-none d-lg-block d-xl-none"></br> i live'y z
                popularnymi trenerami make-up'u <br className="d-none d-lg-block d-xl-none"></br> i biznesu.
              </div>
            </Col>
          </Row>
          </div>

          {/* bottom section */}

          <Row className="mt-5">
            <Col className="p-0">
              <div className={css.ytWrap}>
                <img src={CleoDesktop} alt="cleo" className="w-100 d-none d-md-block" />
                <img src={CleoMobile} alt="cleo" className="w-100 d-md-none" />
                <div className={`${css.ytHeader} p-3 p-lg-5`}>
                  <h1>Zostań<span className={css.secondary}> jedną z nas!</span></h1>
                  <div className={`${css.ytLink} d-flex align-items-center mt-3`} onClick={this.openModal}>
                    <img src={iconPlay} alt="play" />
                    <div className={`${css.ytSubtitle} ms-3 fs-5`}><b><span className={css.secondary}>SPRAWDŹ</span>, DLACZEGO WARTO</b></div>
                    </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="my-lg-5 my-2">
            <Col className="p-0">
            <div className="d-flex flex-column flex-md-row align-items-center">
            <div>
              <a className={css.button} href="#" target="_blank" rel="noreferrer" onClick={this.scrollToTop}>
                DOŁĄCZ DO MOJEGO ZESPOŁU
              </a>
            </div>
          <div><img src={arrowUp} alt="arrow up" className={css.pointer + " ms-3"} onClick={this.scrollToTop} /></div>

            </div>
            </Col>
          </Row>
          <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="YrBleL_vXSg"
          onClose={() => this.setState({ isOpen: false })}
        />
        </BootstrapContainer>
      </div>
    );
  }
}

export default Template11;
